dmx.Component('select-multiple', {

  extends: 'select',

  initialData: {
    value: [],
  },

  attributes: {
    value: {
      type: Array,
      default: null,
      alwaysUpdate: true,
    },
  },

  performUpdate (updatedProps) {
    dmx.Component('select').prototype.performUpdate.call(this, updatedProps);

    if (updatedProps.has('value')) {
      this._setValue(this.props.value, true);
    }
  },

  _setValue (value, defaultValue) {
    if (value == null) value = '';
    if (!Array.isArray(value)) value = [value];
    value = value.map(v => v.toString());

    Array.from(this.$node.options).forEach(option => {
      option.selected = value.includes(option.value);
      if (defaultValue) option.defaultSelected = option.selected;
    });

    this._updateValue();
  },

  _updateValue () {
    const value = Array.from(this.$node.options).filter(option => option.selected).map(option => option.value);

    dmx.batch(() => {
      dmx.Component('select').prototype._updateValue.call(this);
      this.set('value', value);
    });
  },

});
